<template>
  <b-card>
    <base-info
      :geturl="'checklist'"
      :fields="fields"
      :baseroute="baseroute"
      :isdelete="isAction"
      :isedit="false"
      :isAction="isAction"
      @readData="showInfo">

      <template v-slot:buttonaction>
        <b-button
          v-if="isAction"
          class="mr-1"
          size="sm"
          variant="primary"
          type="button"
          @click.prevent="isEdit=true"
        >
          
          <span>Edit</span>
        </b-button>
      </template>
    </base-info>

    <basetable
      v-if="dataFile"
      :dataurl="dataurl"
      :fields="fieldFile"
      :is-filter="false"
      :is-pagination="false"
      :is-view="false"
      :is-edit="false"
      :is-delete="false"
      @showData="showData">

      <template #cell(index)="data">
        {{data.index+1}}
      </template>
      <template #cell(is_exist)="data">
        <b-form-checkbox 
          v-model="data.item.is_exist"
          class="custom-control-primary"
          name="check-button"
          :disabled="!isEdit"
        />
      </template>

    </basetable>
    <b-button
      v-if="isEdit && dataFile"
      class="mr-1"
      size="sm"
      variant="warning"
      type="button"
      @click.prevent="isEdit=false"
    >
      
      <span>Batal</span>
    </b-button>
    <b-button
      v-if="isEdit && dataFile"
      class="mr-1"
      size="sm"
      variant="primary"
      type="button"
      @click.prevent="update()"
    >
      
      <span>Simpan</span>
    </b-button>
  </b-card>
</template>

<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BFormCheckbox, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    basetable,
    BCard,
    BFormCheckbox,
    BaseInfo,
    BButton
  },
  data() {
    return {
      dataurl:'/file-type?filter[is_required]=true&filter[type]=spb',
      baseroute:'finance-checklist',
      fields: [
        { key: 'spb.region.name', label: 'Tagihan Sewa Kendaraan Region', sortable: true },
        { key: 'spb.vendor.name', label: 'Mitra', sortable: true },
        { key: 'spb.contract_number', label: 'Surat Perjanjian / SPK', sortable: true },
        { key: 'spb.skko.number', label: 'Sumber Anggaran (SKK O/I)', sortable: true },
        { key: 'total_price', label: 'Nilai Tagihan', sortable: true, type:'number' },
        { key: 'spb.date', label: 'Periode Tagihan', sortable: true, type:'date', format:'MMMM YYYY' }
      ],
      fieldFile: [
        { key: 'index', label: 'No', sortable: true },
        { key: 'name', label: 'Uraian', sortable: true },
        { key: 'is_exist', label: 'Checklist', sortable: true},
        { key: 'file', label: 'File', sortable: true},
      ],
      isEdit:false,
      isAction:true,
      dataFile : null,
      fileDetail:null
    }
  },
  // beforeMount(){
  //   this.fetchData()
  // },
  methods:{
    showData(result){
      this.dataFile.details.forEach(value => result.find(x => x.id == value.file_type_id).is_exist = value.is_exist)
      if(this.dataFile.spb.files){
        this.dataFile.spb.files.forEach(file => result.find(x => x.id == file.file_type_id).file = file.file)
      }
      this.fileDetail = result
    },
    async fetchData(){
      let {data} = await this.$http.get('checklist/'+this.$route.params.id)
      this.dataFile = data  
    },
    showInfo(result){
      this.dataFile = result
      let details = this.dataFile.details
      if(!details.find(x => x.is_exist == false))
        this.isAction = false

      let spb = this.dataFile.spb
      if(spb){
        if(spb.items){
          this.dataFile.total_price = 0
          spb.items.forEach(value => this.dataFile.total_price += this.findItemPrice(value))
        }
      }
      result.isAction = this.isAction
      this.$emit('showData', result)
    },
    findItemPrice(item){
      let price = 0
      if(item.duration_unit == 'day')
        price = item.duration*item.price
      else if(item.duration_unit == 'month')
        price = (item.duration*30)*item.price
      else if(item.duration_unit == 'year')
        price = (item.duration*365)*item.price

      return price
    },
    update(){
      this.fileDetail = this.fileDetail.map(value => {
        return {
          ...value,
          file_type_id: value.id,
          is_exist: value.is_exist?true:false,
        }
      })
      this.$http.put('checklist/'+this.$route.params.id, {details:this.fileDetail}).then(res=>{
        if(res.data.data.details){
          let details = res.data.data.details
          if(!details.find(x => x.is_exist == false))
            this.isAction = false
        }
        this.successSubmit()
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },
    successSubmit(){
      this.$bvToast.toast('', {
        title: 'Submit Success',
        solid: true,
        variant:'success'
      })
      this.isEdit = false
    },
    errorSubmit(err){
      const msg = !err.response?'':err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>