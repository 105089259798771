<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        style="align-self: center; text-align: end;"
      >
        <b-button
          v-if="isUpload"
          class="mr-1"
          size="sm"
          variant="success"
          v-b-modal="'modal-select-doc'"
        >
          <span >Upload File</span>
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          @click="print"
        >
          <span v-if="loading">
            <b-spinner small />
            Loading...
          </span>
          <span v-else>Print</span>
        </b-button>
      </b-col>
    </b-row>
    <info v-if="isInfo" @showData="showData" />
    <b-modal
        :id="'modal-select-doc'"
        centered
        title="Upload File"
        hide-footer
        no-close-on-backdrop
      >
        <div>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <form-file
                    v-model="file"
                    label="Judul File"
                  />
                  <form-v-select
                    v-model="file_type"
                    :selected="file_type"
                    rules="required"
                    dataurl="file-type?filter[is_required]=true&filter[type]=spb&length=1000"
                    label="Tipe File"
                    placeholder="Select Value"
                  />
                </b-col>
                <b-col cols="12">
                  <hr>
                  <b-form-group
                    label-cols-md="3"
                    label=""
                  >
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="primary"
                      type="button"
                      @click.prevent="validationForm"
                    >
                      <span v-if="loading">
                        <b-spinner small />
                        Loading...
                      </span>
                      <span v-else>Upload</span>
                    </b-button>
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="warning"
                      type="button"
                      @click.prevent="$bvModal.hide('modal-select-doc')"
                    >
                      <span>Batal</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
  </b-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {BCard, BRow, BCol, BSpinner, BButton, BForm, BFormGroup} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'

import Info from './include/Info.vue'

export default {
  components: {
    ValidationObserver,
    BCard,
    Info,
    BRow, 
    BCol, 
    BSpinner,
    BButton,
    FormVSelect,
    FormFile,
    BForm,
    BFormGroup
  },
  data(){
    return{
      loading:false,
      isUpload:false,
      file_type:null,
      file:null,
      model:null,
      isInfo:true
    }
  },
  methods:{
    showData(data){
      this.model = data
      this.isUpload = this.model.isAction
    },
    print(){
      this.loading = true
      this.$http.get('checklist/'+this.$route.params.id+'/print').then(res=>{
        this.loading = false
        window.open(res.data.filename)
      })
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          let formData = new FormData()
          formData.append('file', this.file)
          this.$http.post('/upload/image', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(res=>{
            if(res.data){
              let params = {
                file_type_id: this.file_type.id,
                file: res.data.path
              }
              this.$http.post('spb/'+this.model.spb.id+'/file', params)
              .then(()=>{
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})
            }else{
              return this.$bvToast.toast('Gagal Upload File', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            }
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.loading = false
      this.$refs.simpleRules.reset()
      this.$bvModal.hide('modal-select-doc')
      this.isInfo = false
      this.$nextTick().then(() => {
        this.isInfo = true
      })

      this.$bvToast.toast('Upload File Berhasil', {
        title: 'Success',
        solid: true,
        variant:'success'
      })
    },
    errorSubmit(err){
      const msg = err.response?err.response.data.message:''
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>
